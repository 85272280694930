@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.select {
  background-color: rgb(7, 207, 0);
  color: white;
  box-shadow: 0 0 1px black;
}

.wrong {
  background-color: rgb(233, 0, 0);
  color: white;
  box-shadow: 0 0 1px black;
}


.Toastify__toast-container {
  width: auto !important;
}

.Toastify__toast--error {
  background-color: #d32f2f !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
  width: 100% !important;
  color: #fff !important;
}

.Toastify__toast--success {
  background-color: black !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
  width: 100% !important;
  color: white !important;
}

.mySwiper .swiper-button-next:after, .swiper-button-prev:after {
  font-size: 15px !important;
}

.minSwiper .swiper-button-next:after, .swiper-button-prev:after {
  font-size: 15px !important;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next{
  left: 8px !important;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev{
    right: 3px !important;
}

.swiper-button-next,
.swiper-button-prev {
  right: 10px;
  color: white !important;
  fill: white !important;
  stroke: white !important;
}

.mySwiper .swiper-button-next:after,
.mySwiper .swiper-button-prev:after {
  font-size: 12px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #0f172a !important;
  color: white !important;
  fill: white !important;
  stroke: white !important;
  width: 20px !important;
  padding-left: 29px !important;
  padding-top: 28px !important;
  padding-bottom: 28px !important;
  padding-right: 20px !important;
  border-radius: 50px !important;
  height: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0f172a !important;
}

.swiper-slide{
  left: 12px !important;
  margin-right: 0px !important;
  width: auto !important;
}
